import {
  useExternalStorage,
  defineNuxtRouteMiddleware,
  navigateTo,
  createError,
} from '#imports'

import type r4 from 'fhir/r4'

import { getSourcesStore } from '@/stores/sources'
import { reportError } from '@/utils/reportError'

export default defineNuxtRouteMiddleware(async (to) => {
  // Iframes have to be rendered in order to extract source data, so we have to redirect to a specific route to handle this.
  if (String(to.query.type) === 'iframe') {
    return navigateTo({ name: 'resources-import-iframe', query: to.query })
  }

  const externalStorage = useExternalStorage()
  const sources = await getSourcesStore()

  try {
    const response = await externalStorage.get(String(to.query.id))

    const newSourcePromises: ReturnType<(typeof sources)['create']>[] = []

    for (const source of response.sources) {
      newSourcePromises.push(
        sources.create(
          source as r4.Questionnaire,
          response.responses.find((questionnaireResponse) => {
            return questionnaireResponse.questionnaire === source.url
          }) as r4.QuestionnaireResponse,
          response.config,
          response.version,
        ),
      )
    }

    const newSources = await Promise.all(newSourcePromises)

    if (!newSources.length) {
      return createError({ statusCode: 404 })
    }

    return navigateTo({
      name: 'edit-questionnaire-code',
      params: { id: newSources.at(-1)?.id },
    })
  } catch (error) {
    reportError('failed to import', error)

    return createError({ statusCode: 404 })
  }
})
